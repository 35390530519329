.full-img-background {
  background-size: cover;
  height: 500px;
  position: relative;


  .full-img-inhalt {
    .full-img-title {
      color: $primary-color;
      padding-top: 15%;
    }

    h4 {
      color: $white;
      font-weight: 400;
      width: 50%;
    }
  }
}  