.main-nav {
  height: 62px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  .open {
    height: 90vh!important;
  }

  .main-nav-container {
    position: relative;
    height: auto;
    padding: 0 20px;

    .logo {
      display: inline-block;
      position: absolute;
      right: 0;
      padding: 15px 20px;
      width: 160px;
      height: 107px;
  
      a {
        color: $white;
        font-size: 24px;
      }
    }
  }

  a {
    &.logo-circle-bg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 15px;
      background: url('../img/svg/labestia-circle-logo.svg') no-repeat;
      width: 140px;
      height: 140px;
      background-size: 140px 140px;
      z-index: 999;

      @include breakpoint(small only) {
        width: 80px;
        height: 80px;
        background-size: 80px 80px;
      } 

      @include breakpoint(321px down) {
        width: 70px;
        height: 70px;
        background-size: 70px 70px;
      }

    }

    &.fb-nav-icon {
      position: absolute;
      right: 65px;
      top: 15px;
      background: url('../img/svg/fb-footer-icon.svg') no-repeat;
      width: 36px;
      height: 36px;
      background-size: 36px 36px;
      z-index: 999;

      @include breakpoint(small only) {
        width: 26px;
        height: 26px;
        background-size: 26px 26px;
        right: 60px;
      } 
    }

    &.insta-nav-icon {
      position: absolute;
      right: 20px;
      top: 15px;
      background: url('../img/svg/insta-footer-icon.svg') no-repeat;
      width: 36px;
      height: 36px;
      background-size: 36px 36px;
      z-index: 999;

      @include breakpoint(small only) {
        width: 26px;
        height: 26px;
        background-size: 26px 26px;
      } 
    }
  }

  [hidden] {
    display: none;
    font-weight: 400;
  }

  .menu-footer {
    opacity: 0; 
    z-index: 9999; 
    position: absolute;
    top: 89%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include breakpoint(small down) {
      top: 65%;
      width: 95%;
    }
    
    .opening-hours-nav-inner {
      padding: 0 0 0 70px;
      position: relative;
      display: flex;
      justify-content: left;
      width: 100%;

      @include breakpoint(small only) {
        background-color: transparent;
        padding: 0;
        justify-content: center;
      }
  
      h2 {
        font-size: rem-calc(15);
        font-weight: 800;
        margin-bottom: 1rem;
        color: $white;
      }
  
      a {
        color: $white;
        &.tel {
          font-weight: 600;
          font-size: rem-calc(16);
          border-bottom: 2px solid $primary-color;
          color: $white;
        }
      }
  
      ul {
        display: inline-flex;

        @include breakpoint(small only) {
          display: block;
        }
  
        li {
          list-style-type: none;
          padding: 20px;


          &:nth-of-type(1) {
            position: relative;

            @include breakpoint(small only) {
              display: none;
            }

            &:before {
              content: '';
              display: block;
              background: url('../img/svg/footer-logo.svg') no-repeat;
              width: 43px;
              height: 21px;
              background-size: 43px 21px;
              position: absolute;
              top: -25px;
              left: 15px;
        
              @include breakpoint(small down) {
                left: 5px;
              }
            }
        
          }
  
          &:nth-of-type(2) {
            width: 370px;
            margin: 0 40px;

            @include breakpoint(small only) {
              width: auto;
              margin: 0;

              h2 {
                display: none;
              }
            }
          }

          &:nth-of-type(3) {

            @include breakpoint(small only) {
              h2 {
                text-align: center;
              }
            }
          }
  
          .days-inner {
            text-align: right;
            margin-bottom: 15px;
          }
  
          &.box-container {
            width: 180px;
          }
  
          @include breakpoint(880px down) {
            padding: 20px 10px;
          }

          @include breakpoint(small only) {
            padding: 4px 10px;
          }
  
          p {
            font-weight: 500;
            font-size: rem-calc(12);
            color: $white;
  
            &.days {
              border-bottom: 1px solid #e6e6e6;
              padding: 5px 0;
              margin:0;
            }
  
            span {
              font-weight: 600;
              float: left;
            }
          }
  
          a {
            transition: .2s ease;
  
            &:hover {
              text-decoration: underline;
            }
  
            &.btn-black {
              width: 185px;
              padding: 0.6rem;
              text-align: center;
              border-radius: 20px;
              border: 2px solid $white;
              background-color: $black;
              font-size: 12px;
              font-weight: 600;
              color: $white;
              display: block;
              letter-spacing: 2px;
              margin-bottom: 10px;

              @include breakpoint(small only) {
                font-size: 10px;
                width: 100%;
                display: inline-block;
                margin: 0 1px 10px;
              }

              @include breakpoint(321px down) {
                width: 100%;
                margin: 0 1px 10px;
              }
  
              &:hover {
                background-color: $white;
                color: $black;
                border: 2px solid $black;
                text-decoration: none;
              }
            }
  
            &.special-time {
              font-weight: 600;
              font-size: rem-calc(12);
              text-decoration: underline;
            }
  
            &.google-map {
              img {
                width: 17px;
              }
            }
          }
  
          /*.reservation {
            & > p {
              font-weight: 600;
              text-transform: uppercase;
              font-size: rem-calc(12);
              width: 50%;
              margin: 0 auto 10px;
            }
  
            .box {
              position: relative;
              border: 3px solid $primary-color;
              width: 140px;
              height: 140px;
              display: flex;
              justify-content: center;
              align-items: center;  
  
              .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .2s ease;
                background-color: $primary-color;
  
                .overlay-inner {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 100%;
  
                  & > a {
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
  
              &:hover {
                .overlay {
                  opacity: 1;
                }
              }
            }
          }*/
        }
      }
    }

    & > ul {
      &.desktop {
        @include breakpoint(small down) {
          display: none;
        }  

        display: flex;
        justify-content: center;
        float: none;
        margin-bottom: 20px;

        li {
          border-right: 1px solid;
          text-align: center;
          padding: 0 20px;
          z-index: 9999;
          list-style-type: none;
  
          @include breakpoint(small only) {
            padding: 0;
          } 
  
          @include breakpoint(321px down) {
            padding: 0 5px;
          }
  
          &:last-child {
            border-right: 0;
          }
  
          p {
            font-weight: 600;
            font-size: rem-calc(10px);
            margin-bottom: 0;
  
            @include breakpoint(small only) {
              &.days {
                font-size: rem-calc(9px);
                padding: 0 5px;
              }
  
              &.hours {
                font-size: rem-calc(9px);
                padding: 0 20px;
              }
            }
            @include breakpoint(321px down) {
              &.hours {
                font-size: rem-calc(9px);
                padding: 0 10px;
              }
            }  
          }
        }
      }
    }
  }  
  
  button {
    display: inline-block;
    position: relative;
    background-color: transparent;
    font-weight: 800;
    padding: 0;
    border: 0;
    font-size: 0;
    margin: 15px 0 0 8px;
    outline: none;
    z-index: 999;
    cursor: pointer;
    width: 36px;
    height: 37px;
    background-size: 36px 37px;

    &:hover {
      /*background: url('../img/svg/menu-hover.svg') no-repeat;
      //width: 51px;
      height: 37px;
      background-size: 51px 37px;*/
      transform: scale(1.05);

      @include breakpoint(small only) {
        width: 26px;
        height: 30px;
        background-size: 26px 30px;
      } 
    }

    @include breakpoint(small only) {
      width: 26px;
      height: 30px;
    } 

    &::before {
      content: ' ';
      position: absolute;
      border-radius: 200vh;
      opacity: 0;
      left: calc(-130vmax + 50%);
      top: calc(-130vmax + 50%);
      width: 260vmax;
      height: 260vmax;
      background: $black;
      display: none;
      z-index: -1;
      transform: scale3d(1, 1, 1);
      transform-origin: 50% 50%;
      transition: .5s .5s ease-in-out transform, .5s .5s ease-in-out opacity;

      @include breakpoint(450px down) {
        border-radius: 0;
        //background: url('../img/mobile-nav-bg.jpg') no-repeat;
        background-size: contain;
        left: -28px;
        top: -15px;
        width: 100vmax;
        height: 100vmax;
        transform: scale(1);
      }
    }

    &[aria\-expanded=true] {
      color: $black;
    }

    &[aria\-expanded=true]::before {
      display: block;
      opacity: 1;
      transform: scale3d(1, 1, 1);
      transition: .5s ease-in-out transform, .5s ease-in-out opacity;
    }
  }

  ul {
    &#menu {
      pointer-events: none;
      list-style: none;
      width: 100%;
      display: none;
      flex-direction: column;
      position: relative;
      padding-left: 70px;
      z-index: 999;

      @include breakpoint(small down) {
        top: 22px;
        left: 28px;
        padding-left: 0;
      }

      &.menu-bg {
        height: auto;
      }
        li {
          list-style-type: none;
          display: inline-block;
          z-index: 9999;

            a {
            font-family: 'Neuton', serif;
            letter-spacing: 5px;
            text-decoration: none;
            text-transform: uppercase;
            font-size: rem-calc(90px);
            font-weight: 600;
            line-height: 1;
            color: $white;
            opacity: 0;
            display: inline-block;
            transform: translateY(-10px);
            transition: 0.2s 0.5s ease-out opacity, 0.2s 0.5s ease-out transform;
            cursor: pointer;

            @include breakpoint(medium only) {
              font-size: rem-calc(45px);
            }

            @include breakpoint(small only) {
              font-size: rem-calc(30px);
            }
            
            @include breakpoint(321px down) {
              font-size: rem-calc(20px);
            }
      
            &:before {
              position: absolute;
              content: '';
              width: 100%;
              height: 4px;
              display: none;
              background: $second-color;
              bottom: 0;
              transition: all .8s ease-in;

              @include breakpoint(medium down) {
                height: 1px;
              }
            }
      
            &:hover  {
              &:before {
                display: block;
              }
            }
          }    
        }

        li:nth-child(2) a {
          transition: 0.2s 0.7s ease-out opacity, 0.2s 0.7s ease-out transform;
        }

        li:nth-child(3) a {
          transition: 0.2s 0.9s ease-out opacity, 0.2s 0.9s ease-out transform;
        }

        li:nth-child(4) a {
          transition: 0.2s 1.1s ease-out opacity, 0.2s 1.1s ease-out transform;
        }
      
    }
  }

  .nav-contact {
    opacity: 0;
    position: absolute;
    display: none;
    z-index: 999;
    bottom: 0;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
    bottom: -20px;
    

    @include breakpoint(small only) {
      bottom: -10px;
    }  

    @include breakpoint(321px down) {
      bottom: -32px;
    }

    p {
      &.adresse {
        margin-bottom: 5px;
        font-weight: 600;
        font-size: rem-calc(10px);
        color: $white;
      }  
    } 

    a {
      z-index: 999;
      border-bottom: 2px solid $primary-color;

      &.google-map-nav {
        font-size: rem-calc(15px);
        font-weight: 600;
        z-index: 999;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 0;

        p {
          color: $white;
        }

        img {
          width: 20px;

          @include breakpoint(small only) {
            width: 16px;
          } 
        }
      }
    }
  }

  // Modal
  .show-nav {
    cursor: pointer;

    & > p {
      text-decoration: underline;
      font-weight: 600!important;
    }
  }
  
  .close-nav {
    font-size: 16px;
    position: absolute;
    font-weight: 400;
    top: 0;
    right: 0;
    width: 35px;
    height: 30px;
    background: #000;
    color: #fff;
    cursor: pointer;
    border: 0;
    margin: 0;

    &:hover {
      background: #000;
      transform: scale(1);
    }
  }

  .modal-nav {
    position: absolute;
    bottom: -33px;
    left: 0;
    width: 100%;
    height: 35%;
    background: #F7F7F7;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-out;

    @include breakpoint(small only) {
      height: 100%;
    } 

    .modal-inner {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      overflow-wrap: break-word;

      p {
        font-size: rem-calc(12px);
        line-height: 1;
      }

      span {
        font-weight: 600;
      }
    }
  }
  .active {
    visibility: visible;
    opacity: 1;
  }
  .active + .modal-nav {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  .menu-footer:not([hidden]){
    opacity: 1;
    z-index: 999;
    display: grid;
  }

  .nav-contact:not([hidden]){
    @include breakpoint(medium up) {
      display: none;
    }

    display: block;
    opacity: 1;
  }

  #menu:not([hidden]) {
    pointer-events: all;
    display: flex;

    &  a {
      opacity: 1;
      //transform: translateY(0);
    }
  }
}