.front-slider {
  position: relative;

  .background-mobile {
    display: none;

    @include breakpoint(small only) {
      display: block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      position: relative;

      h1 {
        font-size: rem-calc(110px);
        color: $white;
        position: absolute;
        left: 6%;
        top: 40%;
        letter-spacing: 1px;
        line-height: 1;
        width: 65%;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          font-size: rem-calc(50px);
        }

        @include breakpoint(small only) {
          top: 60%;
          left: 30px;
          width: 85%;
          font-size: rem-calc(30px);
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100vh;

    @include breakpoint(small only) {
      display: none;
    }
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    position: relative;

    h1 {
      font-size: rem-calc(110px);
      color: $white;
      position: absolute;
      left: 6%;
      top: 40%;
      letter-spacing: 1px;
      line-height: 1;
      width: 65%;
      text-transform: uppercase;

      @include breakpoint(medium down) {
        font-size: rem-calc(50px);
      }

      @include breakpoint(small only) {
        top: 60%;
        left: 30px;
        width: 85%;
      }
    }
  }

  .logo-center {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 193px;
    z-index: 999;

    a {
      display: block;
      height: 58.5px;
    }
  }

  .marquee {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    background: $primary-color;
    z-index: 99999;

    a {
      color: $black;
      font-weight: 700;
      font-family: 'Neuton', serif;
      font-size: 40px;
      letter-spacing: 3px;
      margin: 10px;

      @include breakpoint(small only) {
        font-size: 22px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .pickup-delivery {
    left: 50%;
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    width: 90vw;
    z-index: 10;
    text-align: center;

    .pickup-button {
      background-color: #fff;
      border-radius: 29px;
      color: #000;
      cursor: pointer;
      font-family: 'Nunito', sans-serif;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 1px;
      padding: 10px 60px 11px;

      @include breakpoint(medium down) {
        font-size: 22px;
      }

      @include breakpoint(small down) {
        font-size: 14px;
        padding: 10px 30px 11px;
      }
    }
  }
}
