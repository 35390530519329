$primary-color: #D8D8D8;
$second-color: #EBE8E5;

body {
  font-family: 'Nunito', sans-serif!important;
  font-weight: 400;

  .visually-hidden {
    display: none;
  }

  .grid-container {
    @include breakpoint(small only) {
      padding-right: 1.625rem;
      padding-left: 1.625rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-family: 'Neuton', serif;
    line-height: 1;
  }

  h1 {
    @include breakpoint(small only) {
      font-size: rem-calc(25px);
    }
  }

  h3{
    &.subtitle {
      font-size: rem-calc(18px);

      @include breakpoint(small only) {
        font-size: rem-calc(16px);
      }
    }
  }

  a,
  p {
    color: $black;
  }

  p {
    font-weight: 500;
  }

  a {
    &:hover,
    &:active,
    &:focus {
      color: $black;
    }
  }

  ul {
    margin: 0;
  }

  .section-container {
    padding: 40px 0;
  }

  .title-container {
    padding-bottom: 40px;
    padding: .7rem 0;
  }

  button {  
    a,
    span {
      font-weight: 800;
      color: $black;
      padding: .7rem 1.2rem;
      border: 5px solid $primary-color;
      transition: color .3s ease-out;
    }

    &:hover {
      a,
      span {
        color: $black;
        background-color: $primary-color;
      }
    }
  }
}