.post {
  padding: 100px 0;

  time {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: rem-calc(20px);
    letter-spacing: 3px;

    @include breakpoint(small only) {
      font-size: rem-calc(35px);
    }
  }

  h1 {
    font-size: rem-calc(40px);
    letter-spacing: 3px;
    margin-bottom: 3rem;
    text-transform: uppercase;

    @include breakpoint(small only) {
      font-size: rem-calc(25px);
    }
  }

  img {
    width: auto;
    margin-bottom: 2rem;

    &.main-img {
      width: 100%;
    }
  }

  p {
    font-weight: 500;
    margin-bottom: 1rem;

    a {
      border-bottom: 3px solid $primary-color;
      font-weight: 600;
      
      &:hover {
        border-bottom: 5px solid $primary-color;
      }
    }
  }

  button {
    a {
      display: inline-block;

      &.btn-black {
        width: 185px;
        padding: 0.6rem;
        text-align: center;
        border-radius: 20px;
        border: 2px solid $white;
        background-color: $black;
        font-size: 12px;
        font-weight: 600;
        color: $white;
        display: block;
        letter-spacing: 2px;
        margin-bottom: 10px;

        @include breakpoint(small only) {
          font-size: 10px;
          width: 100%;
          display: inline-block;
          margin: 0 1px 10px;
        }

        @include breakpoint(321px down) {
          width: 100%;
          margin: 0 1px 10px;
        }

        &:hover {
          background-color: $white;
          color: $black;
          border: 2px solid $black;
          text-decoration: none;
        }
      }
    }
  }
}