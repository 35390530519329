.team {
  padding: 5% 0 6% 0;
  position: relative;

  @include breakpoint(small only) {
    padding: 0 0 6% 0;
  }

  &::before{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    background-color: $second-color;
    height: 430px;
    width: 100%;
    z-index: -1;
  }

  .member-box {  
    p {
      &.position {
        font-weight: 600;
        font-size: rem-calc(10px);
        margin: 10px 0 5px 0;
      }

      &.name {
        font-weight: 600;
        font-size: rem-calc(15px);
        float: right;
      }
    }

    @include breakpoint(small only) {
      text-align: center;

      p{
        &.name {
          float: none;
        }
      }
    }
  }

  .team-gallery-desktop {
    position: relative;

    .swiper-button-next {
      right: -20px;

      @include breakpoint(medium only) {
        right: 0;
      }
    }

    .swiper-button-prev {
      left: -20px;

      @include breakpoint(medium only) {
        left: 0;
      }
    }
  }

  .swiper-button-next, 
  .swiper-button-prev {
    color: black;
    outline: none;
    cursor: pointer;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 24px;
  }
}