.contact-container {
  iframe {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  form {
    margin-top: 20px;
    label {
      display: none;
    }

    [type='text'], 
    [type='password'],
    [type='date'], 
    [type='datetime'], 
    [type='datetime-local'], 
    [type='month'], 
    [type='week'], 
    [type='email'], 
    [type='number'], 
    [type='search'], 
    [type='tel'], 
    [type='time'], 
    [type='url'], 
    [type='color'], 
    textarea {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 2.4375rem;
      margin: 0 0 1rem;
      padding: 0.5rem;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      //box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  }
  }
}