.opening-hours {
  background-color: $black;
  padding: 80px 0;
  position: relative;

  @include breakpoint(small down) {
    display: none;
  }

  &-inner {
    background-color: $black;
    color: $white;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    h2 {
      color: $white;
      font-size: rem-calc(15);
      font-weight: 800;
      margin-bottom: 1rem;
    }

    a {
      &.tel {
        color: $white;
        font-weight: 600;
        font-size: rem-calc(16);
        border-bottom: 2px solid $primary-color;
      }
    }

    ul {
      display: inline-flex;

      li {
        list-style-type: none;
        padding: 20px;

        &:nth-of-type(2) {
          width: 370px;
          margin: 0 60px;

          @include breakpoint(medium only) {
            margin: 0 30px;
          }
        }

        .days-inner {
          text-align: right;
          margin-bottom: 15px;
        }

        &.box-container {
          width: 180px;
        }

        @include breakpoint(880px down) {
          padding: 20px 10px;
        }

        p {
          color: $white;
          font-weight: 500;
          font-size: rem-calc(12);

          &.days {
            border-bottom: 1px solid #e6e6e6;
            padding: 5px 0;
            margin:0;
          }

          span {
            font-weight: 600;
            float: left;
          }
        }

        a {
          color: $white;
          transition: .2s ease;

          &:hover {
            text-decoration: underline;
          }

          &.btn-black {
            width: 185px;
            padding: 0.6rem!important;
            text-align: center;
            border-radius: 20px!important;
            border: 2px solid $white;
            background-color: $black;
            background-image: none!important;
            box-shadow: rgba(255, 255, 255, 0.3) 0px 0px inset!important;
            font-size: 12px!important;
            font-weight: 600;
            color: $white;
            display: block;
            letter-spacing: 2px;
            margin-bottom: 10px;


            &:hover {
              background-color: $white;
              color: $black;
              border: 2px solid $black;
              text-decoration: none;
            }
          }

          &.special-time {
            font-weight: 600;
            font-size: rem-calc(12);
            text-decoration: underline;
          }

          &.google-map {
            img {
              width: 17px;
            }
          }
        }

        .reservation {
          & > p {
            font-weight: 600;
            text-transform: uppercase;
            font-size: rem-calc(12);
            width: 50%;
            margin: 0 auto 10px;
          }

          .box {
            position: relative;
            border: 3px solid $primary-color;
            width: 140px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;  

            .overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              transition: .2s ease;
              background-color: $primary-color;

              .overlay-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;

                & > a {
                  font-weight: 600;
                  text-decoration: underline;
                  cursor: pointer;
                }
              }
            }

            &:hover {
              .overlay {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  // Modal
  .show {
    cursor: pointer;
    p {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .close {
    font-size: 16px;
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 30px;
    background: #000;
    color: #fff;
    cursor: pointer;
    border: 0;
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-out;

    .modal-inner {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      overflow-wrap: break-word;

      p {
        font-size: rem-calc(12px);
        line-height: 1;
      }

      span {
        font-weight: 600;
      }
    }
  }
  .active {
    visibility: visible;
    opacity: 1;
  }
  .active + .modal {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}