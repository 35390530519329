footer {
  background-color: $black;
  padding: 140px 0;

  @include breakpoint(medium down) {
    padding: 70px 0;
  }  

  h5 {
    margin-bottom: 20px;
    color: $white;
  }

  h5 {
    font-size: rem-calc(12px);
  }

  p,
  a {
    color: $white;
    font-weight: 600;
    font-size: rem-calc(10px);
  }

  .footer-logo {
    margin-bottom: 100px;
  }

  .contact-infos {
    position: relative;

    p {
      &.contact-info {
        position: absolute;
        bottom: 0;
        margin-bottom: 1.5rem;

        a {
          &:hover {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .opening-hours-footer {
    p {
      margin-bottom: 1.5rem;
    }
  }

  .social-media-infos {
    position: relative;

    .social-media {
      position: absolute;
      bottom: 0;
      margin-bottom: 1.5rem;

      ul {
        li {
          display: inline-block;
        }
      }
    }
  }

  

  .action-buttons {

    ul {
      display: flex;

      li {
        list-style-type: none;
        text-align: center;
        padding: 0 20px 20px;

        p {
          font-weight: 500;
          font-size: rem-calc(10);

          span {
            font-weight: 600;
          }
        }

        a {

          & > p {
            font-weight: 600;
            text-transform: uppercase;
            font-size: rem-calc(10);
            width: 100%;
            margin: 0 auto 20px;
          }

          .box {
            border: 3px solid $white;
            width: 140px;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint(medium down) {
              width: 110px;
              height: 110px;
            }
          }

          &:hover {

            .box {
              background-color: #C0D9C8;
            }
          }
        }
      }
    }
  }
}