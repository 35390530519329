.about-us {
  padding-top: 65px!important;
  background-color: #b0b0b0;

  h2 {
    &.about-us-main-title {
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  h2 {
    &.gallery-title{
      text-transform: initial;

      @include breakpoint(small only) {
        width: 50%;
        padding-top: 25px;
      }
    }
  }
  h3 {
    &.subtitle {
      line-height: 1.5;
      width: 60%;
      margin: 30px 0 0;

      @include breakpoint(small only) {
        width: 100%;
        line-height: 1.2;
        margin: 30px auto;
      }
    }
  }

  p {
    font-weight: 600;

    @include breakpoint(small only) {
      font-size: rem-calc(15px);
    }
  }

  .gallery {
    position: relative;

    .text-box {
      width: 35%;

      @include breakpoint(small only) {
        width: 65%;
      }
    }
  
    &.gallery-second-section {
      margin: 8% 0;
      position: relative;

      @include breakpoint(small only) {
        margin: 0 0 15%;
      }

      .text-box {
        width: 100%;

        p {
          margin-bottom: 30px;
        }

        @include breakpoint(small only) {
          margin-top: 10px;
          width: 100%;
        }
      }

      img {
        &.main-second-img {
          margin: 80px 0 30px;

          @include breakpoint(small only) {
            margin: 40px 0 30px;
          }
        }

        &.belowleft-second-img {
          @include breakpoint(small only) {
            width: 125px;
          }
        }
      }

     .small-imgs-center {
      position: relative;

      img {
        width: 45%;
        position: absolute;

        @include breakpoint(small only) {
          position: relative;
          width: 48%;
        }

        &:first-of-type {
          bottom: 0;
          left: 0;
        }

        &:last-of-type {
          bottom: 45px;
          right: 0;

          @include breakpoint(1000px down) {
            bottom: 0;
          }

          @include breakpoint(small only) {
            bottom: 0;
            float: right;
          }
        }
      }
    }
    }

    &.gallery-third-section {
      padding-top: 50px;

      @include breakpoint(small only) {
        padding-top: 0;
      }

      a {    
        &.btn-black {
          width: 185px;
          padding: 0.6rem;
          text-align: center;
          border-radius: 20px;
          border: 2px solid $white;
          background-color: $black;
          font-size: 12px;
          font-weight: 600;
          color: $white;
          display: block;
          letter-spacing: 2px;
          margin-bottom: 10px;
  
          @include breakpoint(small only) {
            font-size: 10px;
            width: 100%;
            display: inline-block;
            margin: 0 1px 10px;
          }
  
          @include breakpoint(321px down) {
            width: 100%;
            margin: 0 1px 10px;
          }
  
          &:hover {
            background-color: $white;
            color: $black;
            border: 2px solid $black;
            text-decoration: none;
          }
        }

        &.btn-black-cards {
          width: 185px;
          padding: 0.6rem;
          text-align: center;
          border-radius: 20px;
          border: 2px solid $black;
          background-color: $black;
          font-size: 12px;
          font-weight: 600;
          color: $white;
          display: block;
          letter-spacing: 2px;
          margin-bottom: 10px;
  
          @include breakpoint(small only) {
            font-size: 10px;
            width: 100%;
            display: inline-block;
            margin: 0 1px 10px;
          }
  
          @include breakpoint(321px down) {
            width: 100%;
            margin: 0 1px 10px;
          }
  
          &:hover {
            background-color: $white;
            color: $black;
            border: 2px solid $white;
            text-decoration: none;
          }
        }
      }

      .text-box {
        width: 80%;
  
        @include breakpoint(small only) {
          width: 100%;
        }
        
      }

      .cards {
        padding-top: 20px;

        a {
          
          &:active {
            color: $black;
          }

          &:hover {
            border-bottom: 2px solid $primary-color;
          }
        }        
      }

      .gallery-third-section-left {
        position: relative;

        img {

          &.left-center-img {
            float: right;

            @include breakpoint(1000px down) {
              width: 50%;
            }

            @include breakpoint(small only) {
              float: none;
              width: 100%;
              margin-top: 20px;
            }
          }
  
          &.left-left-img {
            position: absolute;
            top: 0;
            left: 15%;

            @include breakpoint(1000px down) {
              position: relative;
              left: 0;
            }

            @include breakpoint(small only) {
              display: none;
            }
          }
        }
      }

      .gallery-third-section-right {
        position: relative;

        img {

          &.right-img {
            margin-top: 70px;

            @include breakpoint(1140px down) {
              margin-top: 20px;
            }

            @include breakpoint(1000px down) {
              display: none;
            }

            @include breakpoint(small only) {
              margin: 50px 0 20px;
            }
          }

          &.right-below-img {
            position: absolute;
            bottom: 30px;
            left: 0;

            @include breakpoint(small only) {
              position: relative;
              bottom: 0;
              width: 48%;
            }
          }

          &.right-below-second-mobile-img {
            display: none;

            @include breakpoint(small only) {
              display: block;
              width: 48%;
              float: right;
            }
          }
        }
      }
    }

    &.gallery-fourth-section {
      padding: 125px 0;

      @include breakpoint(small only) {
        padding: 30px 0;
      } 

      .text-box {
        width: 75%;
        float: right;
  
        @include breakpoint(small only) {
          margin-top: 30px;
          width: 100%;
        }       
      }

      .gallery-fourth-section-left {
        img {
          &.gallery-fourth-section-left-beers-mobile {
            display: none;

            @include breakpoint(small only) {
              display: block;
              margin-top: 40px;
            }
          }
        }
      }

      .gallery-fourth-section-center {
        img {
          &.gallery-fourth-section-center-beers {
            @include breakpoint(small only) {
              display: none;
            }
          }
        }
      }

      .gallery-fourth-section-left {
        img {
          margin-top: 115px;

          @include breakpoint(small only) {
            margin-top: 30px;
            width: 100%;
          }
        }
      }

      .gallery-fourth-section-center {
        position: relative;

        img {

          &.below-left {
            position: absolute;
            margin-top: 20px;
            bottom: 0;
            left: -100px;

            @include breakpoint(1000px down) {
              position: relative;
              margin-top: 50px;
              left: 0;
            }


            @include breakpoint(small only) {
              display: none;
              position: relative;
              left: 0;
              width: 100%;
            }
          }

          &.below-right {
            position: absolute;
            margin-top: 20px;
            bottom: 0;
            right: 65px;

            @include breakpoint(1150px down) {
              right: 0;
            }

            @include breakpoint(small only) {
              position: relative;
              right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }

  img {
    &.main-img {
      margin: 30px 0;
    }

    &.img-gallery-right-second {
      position: absolute;
      top: 42%;
      right: 22%;
    }
  }
}