.catering-bg {
  background: url('../img/catering-bg.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;

  @include breakpoint(small only) {
    background: url('../img/catering-bg-mobile.jpg') no-repeat;
    background-size: cover;  
  }

  .logo-center-white {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 193px;
    z-index: 999;

    a {
      display: block;
      height: 58.5px;
    }
  }

  h1 {
    font-size: rem-calc(110px);
    color: $white;
    position: absolute;
    left: 6%;
    top: 40%;
    letter-spacing: 1px;
    line-height: 1;
    width: 65%;
    text-transform: uppercase;

    @include breakpoint(medium down) {
      font-size: rem-calc(50px);
    }

    @include breakpoint(small only) {
      top: 60%;
      left: 30px;
      width: 85%;
      font-size: rem-calc(30px);
    }
  }
}

.catering-services {
  background-color: #b0b0b0;
  padding: 100px 0;

  h1 {
    font-family: 'Nunito', sans-serif;
    font-size: rem-calc(40px);
    font-weight: 400;
    margin-bottom: 45px;
    text-transform: uppercase;

    @include breakpoint(medium only) {
      font-size: rem-calc(50px);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(25px);
    }
  }

  h2 {
    font-size: rem-calc(25px);
    @include breakpoint(small only) {
      font-size: rem-calc(20px);
    }
  }

  .catering-text {
    text-transform: initial;
    width: 85%;
    margin: 85px auto 0;

    @include breakpoint(small only) {
      width: 100%;
    }  
  }

  .card {
    border: 0;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
    height: 350px;
    margin-bottom: 2rem;
    text-align: left;

    @include breakpoint(780px down) {
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      height: 435px;
    }

    @include breakpoint(small down) {
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
      height: 380px;
    }

    &:hover {
      -webkit-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.30);
      -moz-box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.30);
      box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.30);
    }

    .card-section {
      padding: 3rem 2rem;

      @include breakpoint(small only) {
        text-align: left;
      } 

      h2 {
        font-size: rem-calc(25px);
        margin-bottom: 35px;
        text-transform: capitalize;
        width: 60%;

        @include breakpoint(small only) {
          font-size: rem-calc(20px);
          width: 100%;
        }
      }

      p {
        font-family: 'Nunito', sans-serif;
        margin-bottom: 40px;

        @include breakpoint(321px down) {
          font-size: rem-calc(13px);
        }

        & > a {
          border-bottom: 3px solid $primary-color;
          font-weight: 600;
          
          &:hover {
            border-bottom: 5px solid $primary-color;
          }
        }
      }
    }
  }
}

.catering-services-mobile {
  position: relative;

  .swiper-button-next, 
  .swiper-button-prev {
    color: black;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 24px;
  }
}

.catering-sortiment {
  background-color: #ffffff;
  padding: 80px 0 10% 0;

  @include breakpoint(small only) {
    padding-top: 70px;
  }

  h1 {
    font-family: 'Nunito', sans-serif;
    font-size: rem-calc(40px);
    font-weight: 400;
    margin-bottom: 45px;
    text-transform: uppercase;

    @include breakpoint(small only) {
      font-size: rem-calc(25px);
    }
  }

  h2 {
    font-size: rem-calc(25px);

    @include breakpoint(small only) {
      font-size: rem-calc(20px);
    }
  }

  .card {
    background: none;
    border: 0;
    height: auto;
    //height: 230px;
    margin-bottom: 2rem;

    @include breakpoint(780px down) {
      height: auto;
    }

    .card-section {
      padding: 1rem 1rem 1rem 0;

      @include breakpoint(small only) {
        text-align: left;
      }

      h2 {
        font-size: rem-calc(30px);
        margin-bottom: 35px;
        text-transform: capitalize;

        @include breakpoint(small only) {
          font-size: rem-calc(20px);
        }
      }

      h3 {
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        font-size: rem-calc(20px);
        margin-bottom: 0;
        text-transform: uppercase;

        @include breakpoint(small only) {
          font-size: rem-calc(16px);
        }
      }

      p {
        margin-bottom: 40px;
        font-family: 'Nunito', sans-serif;
      }
    }
  }
}