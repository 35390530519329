.logo-center-white {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 193px;
  z-index: 999;

  a {
    display: block;
    height: 58.5px;
  } 
}

.menu-section {

  .background-mobile {
    display: none;

    @include breakpoint(small only) {
      display: block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      position: relative;

      h1 {
        font-size: rem-calc(110px);
        color: $white;
        position: absolute;
        left: 6%;
        top: 40%;
        letter-spacing: 1px;
        line-height: 1;
        width: 65%;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          font-size: rem-calc(50px);
        }

        @include breakpoint(small only) {
          top: 60%;
          left: 30px;
          width: 85%;
          font-size: rem-calc(30px);
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100vh;

    @include breakpoint(small only) {
      display: none;
    }
  } 
  
    .swiper-slide {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: auto;
      position: relative;

      h1 {
        font-size: rem-calc(110px);
        color: $white;
        position: absolute;
        left: 6%;
        top: 40%;
        letter-spacing: 1px;
        line-height: 1;
        width: 65%;
        text-transform: uppercase;

        @include breakpoint(medium down) {
          font-size: rem-calc(50px);
        }

        @include breakpoint(small only) {
          top: 60%;
          left: 30px;
          width: 85%;
          font-size: rem-calc(30px);
        }
      }
    }

  p {
    font-weight: 600;
    margin: 20px 0 0;
  }
}

.menu-cards {
  background-color: #b0b0b0;
  padding: 70px 0;

  h1 {
    font-family: 'Nunito', sans-serif;
    font-size: rem-calc(40px);
    font-weight: 400;
    margin-bottom: 45px;
    text-transform: uppercase;

    @include breakpoint(medium only) {
      font-size: rem-calc(50px);
    }

    @include breakpoint(small only) {
      font-size: rem-calc(25px);
    }
  }

  .card {
    border: none;
    height: 440px;
    text-align: left;

    .card-section {
      padding: 3rem 1rem;

      h2 {
        font-size: rem-calc(30px);
        margin-bottom: 35px;
        text-transform: capitalize;

        @include breakpoint(small only) {
          font-size: rem-calc(20px);
        }
      }

      p {
        font-weight: 600;
        margin-bottom: 40px;
        height: 100px;
        font-family: 'Nunito', sans-serif;
      }

      a {
        .box {
          border: 3px solid $primary-color;
          width: 140px;
          height: 140px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {

          .box {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}
